<template>
  <cipher-text-area
    :value="cipherText"
    :isValid="isCipherTextValid"
    invalidFeedback="Zadej šifrový text pro prolomení"
    @valueChanged="cipherTextChanged"
  />

  <button @click="solve" class="danger-btn">Prolomit</button>

  <div v-show="isSolving" class="solve-alert">Probíhá prolamování...</div>

  <solve-results :results="results" />
</template>

<script>
import CipherTextArea from '@/components/input/CipherTextArea'
import SolveResults from '@/components/solve/SolveResults'

import { solveRoute } from '@/solve/route.worker.js'

export default {
  components: {
    'cipher-text-area': CipherTextArea,
    'solve-results': SolveResults
  },
  data() {
    return {
      cipherText: '',
      results: [],
      isSolving: false
    }
  },
  methods: {
    cipherTextChanged(value) {
      this.cipherText = value
      this.results = []
    },
    solve() {
      if (this.isSolving) return

      if (this.isCipherTextValid) {
        this.isSolving = true
        solveRoute(this.cipherText)
          .then(result => {
            this.results.push(result)
            this.isSolving = false
          })
      }
    }
  },
  computed: {
    isCipherTextValid() {
      return this.cipherText.length > 0
    }
  }
}
</script>