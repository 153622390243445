<template>
  <section v-show="results.length != 0" class="solve-results">
    <h2>Výsledky (od nejlepších)</h2>

    <ul class="result-list">
      <li v-for="(result, index) in orderedResults" :key="index">
        {{ result.text }}
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    results: Array
  },
  computed: {
    orderedResults() {
      return [...this.results].sort((x, y) => {
        if (x.score < y.score) return 1
        else if (x.score > y.score) return -1
        else return 0
      })
    }
  }
}
</script>

<style scoped>
.solve-results {
  margin-top: 2em;
}

li {
  word-wrap: break-word;
  border: 1px dashed #ccc;
  padding: .6em .8em;
  margin-bottom: .4em;
  color: #444444;
  border-radius: 3px;
}
</style>